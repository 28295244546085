import logo from '../static/external/img/logo.png'

const settings = {
    API_URL: "https://biostories.skylo-test3.pl",
    // API_URL: "http://localhost:5000",
    logo: logo,
    homepage: "https://biostories.skylo-test3.pl"
    // homepage: "http://localhost:5000"
}

export default settings;
