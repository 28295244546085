import React from 'react';

const AboutUs = () => {
    return <section className="aboutUs">
        <h2 className="row__header">
            Poznaj nas bliżej
        </h2>
    </section>
};

export default AboutUs;
